import Divider from "kreate-common/modules/kreate-ui/components/Divider";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import { AiFillInstagram } from "react-icons/ai";
import {
  FaEnvelope,
  FaDiscord,
  FaFacebook,
  FaTelegramPlane,
  FaGithub,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import LogoKreate from "./components/LogoKreate";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function Footer({ className, style }: Props) {
  return (
    <div className={className} style={style}>
      <hr className={styles.divider} />
      <Flex.Row
        maxWidth="calc(100% - 112px)"
        padding="32px 56px"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap="10px"
        className={styles.container}
      >
        <Flex.Row alignItems="center" gap="24px" className={styles.content}>
          <LogoKreate />
          <Flex.Cell className={styles.tagline}>
            {"Web3 Art Community"}
          </Flex.Cell>
        </Flex.Row>
        <Flex.Row className={styles.content}>
          <Flex.Row gap="12px" alignItems="center">
            <Flex.Cell className={styles.text} style={{ marginRight: "12px" }}>
              {"Contact Us"}
            </Flex.Cell>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hello@kreate.community"
              className={styles.link}
            >
              <FaEnvelope />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/KreatePlatform"
              className={styles.link}
            >
              <FaXTwitter />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.gg/VD5S9AUwXr"
              className={styles.link}
            >
              <FaDiscord />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/kreate.community"
              className={styles.link}
            >
              <AiFillInstagram />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/kreatecommunity"
              className={styles.link}
            >
              <FaFacebook />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/kreate_announcement"
              className={styles.link}
            >
              <FaTelegramPlane />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/kreate-community"
              className={styles.link}
            >
              <FaGithub />
            </Link>
          </Flex.Row>
        </Flex.Row>
      </Flex.Row>
      <Divider.Horizontal className={styles.divider} />
      <Flex.Row
        padding="32px 56px"
        justifyContent="space-between"
        alignItems="center"
        maxWidth="calc(100% - 112px)"
        className={styles.container}
      >
        <Flex.Cell>{"© 2023 Kreate"}</Flex.Cell>
        <Flex.Row gap="20px">
          <Link
            className={styles.textLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://about.kreate.art/"
          >
            {"About Us"}
          </Link>
          <Link className={styles.textLink} href="/faq">
            {"FAQs"}
          </Link>
          <Link
            className={styles.textLink}
            target="_blank"
            rel="noopener noreferrer"
            href="/documents/PrivacyPolicy.pdf"
          >
            {"Privacy Policy"}
          </Link>
          <Link
            className={styles.textLink}
            target="_blank"
            rel="noopener noreferrer"
            href="/documents/TermsOfService.pdf"
          >
            {"Terms of Service"}
          </Link>
        </Flex.Row>
      </Flex.Row>
    </div>
  );
}
